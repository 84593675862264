<template>
  <date-picker v-bind="{ ...$props, ...$attrs }">
    <template #trigger>
      <slot name="trigger"></slot>
    </template>
  </date-picker>
</template>
<script>
import '@/assets/datetimepicker.scss';
import DatePicker from 'vue3-date-time-picker';

export default {
  components: {
    DatePicker,
  },
};
</script>
